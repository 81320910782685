body {
  background-color: #0C0B0F;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  background-color: #0C0B0F;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

video {
  position: fixed;
  max-width: 100vw;
  max-height: 100vw;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 10;
}

.connect-button {
  text-transform: capitalize;
  background-color: #FF2C4A;
  font-size: xx-large;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 10rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  max-width: 100%;
  min-width: 210px;
}
.connect-button.activate {
  font-size: medium;
}

.verify-button {
  text-transform: capitalize;
  background-color: #FFC731;
  font-size: xx-large;
  font-weight: bold;
  border: none;
  border-radius: 10rem;
  padding: 1rem 2rem;
  max-width: 100%;
  min-width: 210px;
}

.verify-button:disabled {
  background-color: #EEB620;
  color: #000;
  font-size: x-large;
}
